html, body {
   background-image: url("../public/img/enfant-left.jpg");
   height: 100%;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   background-attachment: fixed;
}

.donorinfo-link {
   /* display: block; */
   width: 165px;
   height: 76px;
   /* margin: auto; */
   background-image: url("../public/img/donorinfo-logo-nl-v2-1.png");
}

.form-container {
   padding: 2rem 1rem;
   background-color: white;
   margin-top: 5rem;
}

.fancy-font {
   font-family: 'Poppins', sans-serif;
}